/* neutralize materialize settings */
tbody, tfoot, thead, tr, th, td, li, ul, div {
    margin:0;
    padding:0;
    border:0;
    outline:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
}

/* neutralize special color */
button:focus
{
    outline: none;
    background-color: transparent;
}

/* neutralize table settings */
table {
    border-collapse:collapse;
    border-spacing:0;
}